import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const Speakers = (props) => {
  const speakers = props.data.allContentfulSpeaker.edges;

  return (
    <Layout location={props.location}>
      <div className="columns" style={{ marginTop: "4rem" }}>
        <div className="column is-full" style={{ marginBottom: "2rem" }}>
          <h1 className="title is-1">Speakers Hall of Fame</h1>
        </div>
      </div>

      {speakers.map((data) => (
        <Speaker
          key={data.node.id}
          name={data.node.name}
          text={data.node.description.description}
          website={data.node.website}
          photo={data.node.photo.file.url}
        />
      ))}
    </Layout>
  );
};

const Speaker = ({ name, text, website, photo }) => (
  <div className="columns" style={{ marginBottom: "3rem" }}>
    <div className="column is-3">
      <img src={photo} alt={name} style={{ height: "256px" }} />
    </div>

    <div className="column">
      <h2 className="subtitle is-3">
        <a href={website} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </h2>
      <p>{text}</p>
      <p style={{ marginTop: "1rem" }}>
        Website:{" "}
        <a href={website} target="_blank" rel="noopener noreferrer">
          {website}
        </a>
      </p>
    </div>
  </div>
);

export const pageQuery = graphql`
  query {
    allContentfulSpeaker {
      edges {
        node {
          id
          name
          description {
            description
          }
          website
          photo {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Speakers;
